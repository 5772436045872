.case-num {
  display: inline;
  font-size: 1.2rem !important;
  font-weight: 500 !important;
}

.case-num strong {
  color: #37A4A7;
}

.case-num-divider {
  height: 18px !important;
  margin-top: 3px !important;
  border-width: 1px !important;
}

.datatable-qna {
  height: 631px;
}

.qna-pagenation {
  display: flex;
  width: 100%;
  justify-content: center;
}
.qna-detail-root {
  width: 100%;
  height: 100%;
  border: 1px !important;
  border-radius: 1.5rem;
}

.question-root {
  padding-left: 0.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 0.5rem;
  white-space: pre-line;
}

.question-icon {
  font-size: 1.1rem !important;
  font-weight: bold !important;
}

.question-content {
  font-size: 0.95rem;
}

.answer-root {
  padding-left: 0.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 0.5rem;
  background-color: #fff1ef;
}

.answer-waiting {
  padding-left: 0.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 0.5rem;
}

.answer-icon {
  font-size: 1.1rem !important;
  font-weight: bold !important;
  color: #37A4A7;
}

.answer-content {
  font-size: 0.95rem;
}
