html,
body {
  width: 100%;
}

body {
  font-family: "Noto Sans KR", "Roboto", sans-serif;
}

.App {
  background-color: #efefef;
  height: 100%;
}
.main-outlet {
  margin-bottom: 2.5rem !important;
}

.LeftDrawer:hover {
  text-decoration: none;
  background-color: rgb(137, 137, 163) !important;
  color: #ffffff;
  border-radius: 5px;
}
.LeftDrawer.Mui-selected {
  background-color: #ffffff !important;
  border-radius: 5px;
  min-height: 51px;
}
.app-bar {
  background-color: #ffffff;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

/* SIDE BAR */
.main-sidebar {
  /* background-color: #212121; */
  transition: "width 2s";
}
[class*="sidebar-dark"] .brand-link {
  border-bottom: 0;
  padding: 1.3rem 0.5rem 0 0rem;
}
.brand-link .brand-image {
  margin: 0 0 0 0.4rem;
  max-height: 39px;
}
.main-sidebar .brand-text img {
  width: 82px;
}

.main-sidebar .brand-eddy img {
  height: 2rem;
}
.font-weight-light {
  font-weight: 300 !important;
}
.user-panel .image {
  padding-top: 0.13rem;
  padding-left: 0.7rem;
}
.user-panel img {
  width: 2.3rem;
}
.user-panel .info {
  padding: 0 0 0 8px;
  line-height: 34px;
}
.user-panel .info dl {
  color: #fff;
}
.user-panel .info dl dt {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
}
.user-panel .info dl dd {
  font-size: 0.85rem;
  font-weight: 300;
  line-height: 1.2rem;
  color: #aaa;
  letter-spacing: 0.03rem;
  margin-bottom: 0;
}
[class*="sidebar-dark-"] .sidebar a {
  color: #fff;
}
.mt-3 {
  margin-top: 1rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}
.d-flex {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.typo-h4 {
  font-size: 1.5rem !important;
  font-family: inherit !important;
  font-weight: 500 !important;
  color: inherit !important;
}

.h-23 {
  height: 2.3rem !important;
}
.color-black {
  color: #000 !important;
}

.color-white {
  color: #fff !important;
}

.bg-orange {
  background-color: orange;
}

.bg-green {
  background-color: green;
}

.swiper.history {
  width: 100%;
}
.swiper.result-list .slide-cont dl dt {
  font-size: 1.05rem;
  margin-bottom: 4px;
  color: #444;
}
.swiper.result-list .slide-cont dl dd {
  font-size: 0.95rem;
  line-height: 1.3rem;
  color: #666;
  margin-left: 0px;
}
.swiper-button-next,
.swiper-button-prev {
  width: 11px !important;
  height: 60px !important;
  top: None !important;
  display: block !important;
  top: initial !important;
}
.swiper-pagination-fraction {
  left: initial !important;
}

.swiper.result-list {
  background-color: #f5f5f5;
  border-radius: 10px;
  /* margin-top: 16px; */
}
.swiper.result-list .slide-cont {
  padding: 2rem 1.2rem 2.5rem;
}

.swiper.result-list .slide-cont-small {
  padding: 0.2rem 1rem 0.5rem;
}

.swiper.result-list .slide-cont-small .small-title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.swiper.result-list .slide-cont p {
  font-size: 0.95rem;
  line-height: 1.3rem;
  color: #666;
  margin-bottom: 0.8rem;
}
.swiper-button-next {
  background: url("../public/images/slider-next.png") no-repeat center center !important;
  background-size: 11px 20px !important;
  position: absolute !important;
  top: 1rem !important;
  left: 3rem !important;
}
.swiper-button-next::after {
  content: none !important;
}
.swiper-button-prev {
  background: url("../public/images/slider-prev.png") no-repeat center center !important;
  background-size: 11px 20px !important;
  position: absolute !important;
  top: 1rem !important;
  left: 1.2rem !important;
}
.swiper-button-prev::after {
  content: none !important;
}
.swiper-pagination {
  width: 50px !important;
  height: 20px !important;
  position: absolute !important;
  top: 1rem !important;
  left: 4.7rem !important;
  color: #999 !important;
  font-weight: 400 !important;
}

.slide-icon-wrap {
  position: absolute !important;
  bottom: 1.2rem !important;
  right: 1.2rem !important;
}
.slide-icon-wrap .fa-icon:last-child {
  margin-right: 0;
}

.fa-icon {
  margin-right: 0.4rem;
  cursor: pointer;
}
.fa-icon::before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
}
.fa-icon.fa-clone {
  background-image: url("../public/images/icon-clone.png");
  background-repeat: no-repeat;
  background-size: 18px;
}
.fa-icon.fa-thumbs-up {
  background: url("../public/images/icon-thumbs-up.png");
  background-repeat: no-repeat;
  background-size: 36px;
}
.fa-icon.fa-thumbs-down {
  background: url("../public/images/icon-thumbs-down.png");
  background-repeat: no-repeat;
  background-size: 36px;
}
.fa-icon.fa-bookmark {
  background: url("../public/images/icon-bookmark.png");
  background-repeat: no-repeat;
  background-size: 36px;
}
.result-btn-wrap {
  width: 100%;
  position: relative;
}
.fa-icon.fa-thumbs-up.active {
  background-position: -18px 0;
}
.fa-icon.fa-thumbs-down.active {
  background-position: -18px 0;
}
.fa-icon.fa-bookmark.active {
  background-position: -18px 0;
}
.actions {
  padding-top: 0.2rem;
}

.typing {
  /*position: absolute;*/
  display: inline-block;
  animation-name: cursor;
  animation-duration: 0.3s;
  animation-iteration-count: infinite;
  font-size: 2rem !important;
}
@keyframes cursor {
  0% {
    border-right: 1px solid #fff;
  }
  50% {
    border-right: 1px solid #000;
  }
  100% {
    border-right: 1px solid #fff;
  }
}

/* 로딩 모달 */
.modal-loading {
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1038;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.load-cont {
  margin-top: -100px;
}
.spinner-wrap {
  display: flex;
  justify-content: center;
  height: 65px;
}
.spinner {
  width: 12px;
  height: 12px;
  animation: spinner-o824ag 1s infinite linear;
}
.spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #37A4A7;
  border-radius: 50%;
  animation: spinner-vse6n7 1.25s infinite ease;
}
.spinner div:nth-child(1) {
  --rotation: 90;
}
.spinner div:nth-child(2) {
  --rotation: 180;
}
.spinner div:nth-child(3) {
  --rotation: 270;
}
.spinner div:nth-child(4) {
  --rotation: 360;
}
@keyframes spinner-vse6n7 {
  0%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}

@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}
.modal-loading dl {
  color: #fff;
  text-align: center;
}
.modal-loading dl dt {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 16px;
}
.modal-loading dl dd {
  color: #aaa;
}

span.time {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.9rem;
  color: #aaa;
}
span.time-small {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.5rem;
  color: #aaa;
}
.timeline-header {
  margin-bottom: 0.6rem;
  line-height: 1.2rem;
  display: inline-block;
  white-space: pre-line;
  font-weight: 800;
}

.timeline-header-small {
  margin-bottom: 0.1rem;
  line-height: 1rem;
  display: inline-block;
  white-space: pre-line;
  font-size: 0.9rem;
  font-weight: 700;
}
.timeline-header:hover {
  cursor: pointer;
}
.timeline-header a {
  font-size: 1.05rem;
  line-height: 0.8rem;
  color: #444;
}
.kw-wrap {
  position: absolute;
  bottom: 1.2rem;
  left: 1.2rem;
}
.kw-wrap-small {
  /* position: absolute; */
  bottom: 0.5rem;
  left: 1rem;
}
.kw-tag {
  display: inline-block;
  font-size: 0.9rem;
  padding: 0.1rem 0.7rem;
  border: 1px solid #aaa;
  color: #777;
  font-weight: 400;
  border-radius: 2rem;
  margin-right: 0.3rem;
  text-align: center;
}
.kw-tag-small {
  display: inline-block;
  font-size: 0.7rem;
  padding: 0.1rem 0.4rem 0rem 0.4rem;
  border: 1px solid #aaa;
  color: #777;
  font-weight: 400;
  border-radius: 2rem;
  margin-right: 0.3rem;
  text-align: center;
}
.kw-tag.product:hover {
  cursor: pointer;
  color: #444;
}
.kw-tag.keyword:hover {
  cursor: pointer;
  color: #444;
}
.kw-tag-small.product:hover {
  cursor: pointer;
  color: #444;
}
.kw-tag-small.keyword:hover {
  cursor: pointer;
  color: #444;
}
.timeline-body {
  margin-bottom: 0.5rem;
  line-height: 1.3rem;
  font-size: 0.95rem;
  color: #666;
  display: inline-block;
  white-space: pre-line;
}
.timeline-body-small {
  margin-bottom: 0rem;
  line-height: 1rem;
  font-size: 0.8rem;
  color: #666;
  display: inline-block;
  white-space: pre-line;
}
.al-right {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.swiper.timeline-sl {
  margin-top: 0;
}
.swiper.timeline-sl .slide-cont dl dt {
  margin-bottom: 4px;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-0 {
  margin-top: 0;
}

.mis-0 {
  margin-inline-start: 0px !important;
}

.full-width {
  width: 100% !important;
}

.color-999 {
  color: #999;
}

.font-bold {
  font-weight: bold !important;
}
